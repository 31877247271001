$(function() {
    var header = document.getElementById("site_head_wrap");
    var search = document.getElementById("search_home");
    var shade = document.getElementById("shad_menu");

    function onWinScroll() {
        if (!document.body.classList.contains("in_tunnel")) {
            var scrollTop = $(document).scrollTop();

            if (header.classList.contains("sticky")) {
                if (scrollTop === 0) {
                    header.classList.remove("sticky");
                    shade.classList.remove("sticky");
                }
            } else {
                if (scrollTop > 50) {
                    header.classList.add("sticky");
                    shade.classList.add("sticky");
                }
            }
        }
    }

    function onSearchStateChange(evt) {
        if (evt.type === "focus") {
            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {
            search.parentElement.classList.remove("searching");
        }
    }

    $(window).on("scroll", onWinScroll);
    $(search).on({focus: onSearchStateChange, blur: onSearchStateChange});

    onWinScroll();

    /*START modules home*/
   $('.cover-module').each(function(index, element) {
        var $swiperContainer = $(element).find('.home-module-body');
        
        if ($swiperContainer.find('.swiper-slide').length > 1) {
            new Swiper($swiperContainer[0], { // Use [0] to pass the DOM element
                preloadImages: false,
                lazy: true,
                loop: false,
                speed: 1500,
                autoplay: {
                    delay: 3000,
                },
                pagination: {
                    el: $(element).find('.swiper-pagination')[0], // Scope pagination
                },
            });
        }
   });
    
    // TG HOMEPAGE
    // Init swiper 
    if ($('.tg-module-1 #moduleSwiper').length) {
        // iterate through all swipers with the same id
        $(".tg-module-1 #moduleSwiper").each(function (index) {
            var $this = $(this);
            $this.addClass("s-" + index);
            $this.parent().find(".tgPrev").addClass("btn-prev-" + index);
            $this.parent().find(".tgNext").addClass("btn-next-" + index);
            var tgSwiper = new Swiper(".s-" + index, {
                preloadImages: false,
                lazy: true,
                loop: false,
                spaceBetween: 30.4,
                slidesPerView: 5,
                navigation: {
                    nextEl: ".btn-next-" + index,
                    prevEl: ".btn-prev-" + index,
                }
            });

            // if slides < 5
            if (tgSwiper.slides.length <= 5) {

                // hide swiper prev & next btn
                $this.parent().find(".btn-prev-" + index).hide();
                $this.parent().find(".btn-next-" + index).hide();

                // center wrapper 
                $this.children().first().addClass('wrapper-centered');
            }

        });
    }
    /*END modules home*/
});