// Récupération de la valeur de la constante NO_LAZYLOAD pour appliquer le paramètre aux swiper
var isLazyLoad = (typeof is_lazy_load !== 'undefined' && is_lazy_load == false) ? false : true;

$(function () {

    // Gestion du shad pour le menu
    $(".my_account.is_logged").on("mouseenter", addAccountMenu);
    $(".my_account.is_logged .rolloverTxt").on("touchstart", function (e) {
        e.preventDefault();
        addAccountMenu();
    });
    $('#shad_menu').on('touchstart', removeAccountMenu);
    $(".my_account.is_logged").on("mouseleave", removeAccountMenu);

    function removeAccountMenu() {
        $(".my_account.is_logged").removeClass('hover');
        $('#topLogin').removeClass('show');
        $("#shad_menu").removeClass("actif");
    }
    function addAccountMenu() {
        $(".my_account.is_logged").addClass('hover');
        $('#topLogin').addClass('show');
        $("#shad_menu").addClass("actif");
    }
    $(".my_basket, .my_wishlist").on("mouseenter", function () {
        topCartAppear($(this));
    });

    $(".my_basket, .my_wishlist").on("mouseleave", function () {
        topCartDesappear($(this));
    });

    var menu_timeout = setTimeout(null);

    $(".main_menu_itm")
        .not(".menu_itm_hover")
        .on({
            mouseenter: function () {
                var mainmenu = $(".main_menu");

                clearTimeout(menu_timeout);

                mainmenu.addClass("actif");
            },
            mouseleave: function () {
                menuItemClose();
            },
            touchstart: function () {
                var mainmenu = $(".main_menu");

                clearTimeout(menu_timeout);

                mainmenu.addClass("actif");
            },
            touchend: function () {
                menuItemClose();
            }
        });

    $(".menu_itm_hover").on({
        mouseenter: function (evt) {
            var mainmenu = $(".main_menu");
            var submenu = $(".submenu_wrapper", this);

            evt.preventDefault();

            clearTimeout(menu_timeout);

            if (!mainmenu.hasClass("actif")) {
                $("#shad_menu").addClass("actif from_main_menu");
                $(this).addClass('hovering');
                $('#site_head_wrap').addClass('with_shad');

                mainmenu.addClass("actif");

                menu_timeout = setTimeout(function () {
                    submenu.stop(true, true).fadeIn(200);
                }, 200);
            } else {
                submenu.show();
            }
        },
    });

    function menuItemClose(submenu) {
        clearTimeout(menu_timeout);

        if (submenu) {
            submenu.stop(true, true).fadeOut(300);
        }

        $('#shad_menu').fadeOut(100);

        menu_timeout = setTimeout(function () {
            $("#shad_menu").removeClass("actif");
            $(".main_menu").removeClass("actif");

            if ($('#shad_menu.from_main_menu').length) {
                $("#shad_menu").removeClass("from_main_menu");
            }

            if ($('#site_head_wrap.with_shad').length) {
                $('#site_head_wrap').removeClass('with_shad');
            }
        });
    }

    $(document).on("scroll", function () {
        menuItemClose($(".submenu_wrapper:visible"));
    });

    $(".menu_itm_hover").on("mouseleave", function () {
        menuItemClose($(".submenu_wrapper", this));
        $(this).removeClass('hovering');
        $('.main_menu').removeClass('actif');
    });

    $('#trigger_recherche').on("click touch", function () {
        $(this).addClass('triggered');
        $('#form_recherche').fadeIn().css("display", "flex");
        $('#search_home').focus();
        $('#top_wrapper').addClass('active_rollover');
        $('#shad_menu').addClass('actif');
    });

    $('.close_search').on("click touch", function () {
        closeSearch();
    });

    $("#search_home").on('input', function () {
        var value = $("#search_home").val();

        if (value.length >= 3) {
            $('body').addClass('search_in');
        } else {
            $('body').removeClass('search_in');
        }
    });

    if ($(".promo_swiper .swiper-slide").length > 0) {
        promoSwiper = new Swiper(".promo_swiper", {
            centeredSlides: true,
            loop: true,
            slidesPerView: 1,
            navigation: {
                nextEl: '.navpromo.promoNext',
                prevEl: '.navpromo.promoPrev',
            },
            speed: 1500,
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
            autoplayDisableOnInteraction: false
        });
    }

    // Chargement de la tarification
    if ($("#tarification_load").length > 0) {
        // Chargement des différentes tarification
        $.ajax({
            url: path_relative_root + 'ajax_tarification_list.php',
            type: 'post',
            dataType: 'json',
            success: function (res) {
                if (res !== []) {
                    var div = $('<div>', { 'class': 'custom-select custom-select-footer' });
                    var select = $('<select id="country_tarif" name="country">');

                    $.each(res, function (key, val) {
                        var option = $("<option />", { value: key, text: val.pays_nom });

                        if (val.selected) {
                            option.prop('selected', true)
                        }

                        $(option).appendTo(select);
                    });

                    // Event sur le select lorsque l'on change de pays
                    select.on('change', function () {
                        var country_iso = $(this).val();

                        // On va envoyé au serveur que l'on change de pays
                        $.ajax({
                            url: path_relative_root + create_link("ajax_tarification_change_country"),
                            type: 'post',
                            data: 'iso=' + country_iso,
                            success: function () {
                                location.reload();
                            }
                        });
                    });

                    select.appendTo(div);
                    div.appendTo($("#tarification_load"));
                    initCustomSelect('custom-select-footer');
                } else {
                    $("#tarification_load").css('display', 'none');
                }
            }
        });
    }

    if ($('#cookiesLightbox').length) {
        var wrap_btn = $('#cookiesLightbox .form_submit');
        var second_section = $('#cookiesLightbox section:nth-child(2)');

        wrap_btn.appendTo(second_section);
    }

    if ($('#cart_top #gondole_interest .swiper-slide').length) {
        var gondoleInterest = new Swiper("#cart_top #gondole_interest", {
            slidesPerView: 2,
            observer: true,
            observeParents: true,
            spaceBetween: 29,
            navigation: {
                nextEl: '.swiper-interest-next',
                prevEl: '.swiper-interest-prev',
            },
        });
    }

    /*FAQ ~ Charte de nomamge*/
    if ($('body').hasClass('faq')) {
        $('.faq_question').each(function () {
            var question = $(this);

            $(question).wrapInner('<h3></h3>');
        });
    }

    if ($('#cart_slider_wrapper .swiper-slide').length > 1) {
        $('#cart_slider_wrapper').wrap("<div class='gondole'></div>");
        setTimeout(function () {
            var panierSwiper = new Swiper('#cart_slider_wrapper', {
                slidesPerView: 4,
                spaceBetween: 15,
                loop: false,
                speed: 1500,
                autoplay: {
                    delay: 3000,
                },
                observer: true,
                observeParents: true,
            });

            $('#cart_slider_wrapper').css('opacity', '1');
        }, 800);
    }

    // Select TU sizes by default for achat rapide
    $('.size_list [data-nom="TU"]').prop('checked', true);
});

function triggerMenuResp() {
    var menu = $('nav.main_menu');

    if (menu.hasClass('showing')) {
        menu.removeClass('showing');
        $('body').removeClass('reponsive_menu_open');
    } else {
        menu.addClass('showing');
        $('body').addClass('reponsive_menu_open');
    }
}

function closeSearch() {
    var search = $('#form_recherche');

    search.hide();
    $(".results_list").fadeOut();
    $("body").removeClass("search");
    $('#search_home').blur();
    $('#shad_menu').removeClass('actif');
    $('#top_wrapper').removeClass('active_rollover');
    $('#trigger_recherche').removeClass('triggered');
}

// Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
function topCartAppear(el) {
    var show_top_cart_parent = $('.cart_box', el);
    var rollover_parent = show_top_cart_parent.parent().attr('id');

    if (rollover_parent === 'cart_top') {
        var count_bask = $('#cart_top .cart').attr('data-cart');

        if (count_bask > 1) {
            $("#show_top_cart", el).overlayScrollbars({});
        }

        if (count_bask > 2) {
            $(".cart_product_listing", el).overlayScrollbars({});
        }

        if ($('#cart_top #gondole_interest .swiper-slide').length) {
            var gondoleInterest = new Swiper("#cart_top #gondole_interest", {
                slidesPerView: 2,
                observer: true,
                observeParents: true,
                spaceBetween: 29,
                navigation: {
                    nextEl: '.swiper-interest-next',
                    prevEl: '.swiper-interest-prev',
                },
            });
        }

        if ($(window).height() < 930) {
            if (count_bask) {
                $("#show_top_cart", el).overlayScrollbars({});
            }
        }
    } else if (rollover_parent === 'wishlist_top') {
        var count_bask = $('#wishlist_top .cart').attr('data-cart');

        if (count_bask > 3) {
            $(".cart_product_listing", el).overlayScrollbars({});
        }

        if ($(window).height() < 930) {
            if (count_bask) {
                $("#show_top_cart", el).overlayScrollbars({});
            }
        }
    }

    show_top_cart_parent.addClass("show");
    $('#shad_menu').addClass("actif");
}

// Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
function topCartDesappear() {
    var show_top_cart_parent = $('.cart_box');

    show_top_cart_parent.removeClass("show");
    $('#shad_menu').removeClass("actif");
}

function btnLoaderUnloading(btn) {

    "use strict";

    var loader = btn.next(".loader");

    if (loader.length > 0) {
        setTimeout(function () { loader.removeAttr("style").parent().removeClass("loading") }, 200);
    }
}
/**
 * Checks if there are duplicate sizes for the same product in the cart.
 * A product is considered a duplicate if it appears more than once with the same size.
 *
 * @returns {boolean}
 */
function checkIfProductHasDifferentSizes() {
    let products = {};
    let hasDifferentSizes = false;

    $('.cart_product_sizecol.size').each(function () {
        let productId = $(this).attr('data-product-id');
        let size = $(this).attr('data-size-id');


        if (productId && size) {
            if (products[productId]) {
                if ($.inArray(size, products[productId]) === -1) {
                    products[productId].push(size);
                    if (products[productId].length > 1) {
                        hasDifferentSizes = true;
                    }
                }
            } else {
                products[productId] = [size];
            }
        }
    });
    return hasDifferentSizes;
}

function btnLoaderTrigger(evt) {
    var loader = $(this).next(".loader");
    var check = checkIfProductHasDifferentSizes();
    if (!check) {
        if (loader.length > 0) {
            // Disable prevent.default on form in order to allow the submit
            if (!$(this).parents('form').length) {
                evt.preventDefault();
            }

            loader
                .removeAttr("style")
                .parent()
                .addClass("loading");


            if ($(this).attr("href") && test) {
                window.location.assign($(this).attr("href"));
            }
        }
    }
}

$(function () {
    $(document).on("click", ".button", btnLoaderTrigger);
});

$('#search_home').keypress(function (e) {
    var key = e.which;

    if (key == 13 && $('#search_home').val().length >= 3) {
        closeSearch();
    }
});

function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function (elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();
        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function () {
            this.alt = field.alt;
            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

    var productSwiper = new Swiper(".productSwiper", {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
        preloadImages: false,
        lazy: isLazyLoad,
        loadPrevNext: true,
        loadOnTransitionStart: true
    });
}

var main_product_select_html = $('.product_infos_form.not_achat_express #product_selects').html();

function closeMultiShad(id) {
    $('#shad').off('click');
    $("#availabilityInStore").slideUp(600);

    $('#' + id).fadeOut("slow").queue(function () {
        if ($('#shad').is(":visible")) {
            $('#shad').fadeTo("slow", 0, function () {
                $('#shad').css('display', 'none');
            });
        }

        if ($('#shad_abox').is(":visible")) {
            $('#shad_abox').fadeTo("slow", 0, function () {
                $('#shad_abox').css('display', 'none');
            });
        }

        $(this).dequeue();
    });

    if ($('.product_infos_form.not_achat_express #product_selects').html() == '') {
        $('.product_infos_form.not_achat_express #product_selects').html(main_product_select_html);
    }

    if (id == "sendwishlistbox") {
        $("#sendwishlistbox").hide();
        $('#shad').css('opacity', '0');
    }

    if (id === "zoomBox") {
        $("body").css("overflow", "auto");
    }
}

function showDetailOrder(order_id, order_type) {
    if (show_orders_by_type("orders_by_type", order_type, ROOT_PATH))
        $("#order_" + order_id).click();

    $("html, body").animate({
        scrollTop: $('[data-btn-name="order_section"]').offset().top - 100,
    }, 500);
}

function changeLocale(locale, uri, site) {
    $.ajax({
        url: path_relative_root + create_link("ajax_get_uri_by_lang"),
        type: "post",
        data: { lang: locale, url: uri, site: site },
        success: function (data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        },
    });
}

/**
    * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
    * @author Timothy Beard / Aurélien Renault
    * @since  2017-09-27
*/
function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                    '[value="' + size_id + '"]'
                );
                is_disabled = !sizes_tab[size_id];
            }
        }
    }
}

$(".bloc_lightbox_preco").on("click", function () {
    if (!$(this).hasClass("actif")) {
        $(".bloc_lightbox_preco.actif").removeClass("actif");
        $(this).addClass("actif");
    }
});

$.fn.initAssocBloc = function (options) {
    var options = $.extend({ numberItem: 3, }, options);
    var element = $(this);

    function uniq(a) {
        return a.sort().filter(function (item, pos, ary) {
            return !pos || item != ary[pos - 1];
        });
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function reSortInnerElem(elem, sortArr) {
        var i = 0;
        var returnList = [];

        $("div.alt_sld_view", elem).each(function (index) {
            if (i >= sortArr.length) {
                i = 0;
            }

            var tempList = [];
            var j = 0;

            $("div.alt_sld_view", elem).each(function () {
                if ($(this).attr("data-emplacement") == sortArr[i]) {
                    tempList[j] = $(this).prop("outerHTML");
                    j++;
                }
            });

            var randomNum = getRandomInt(0, tempList.length - 1);

            returnList[index] = tempList[randomNum];
            i++;
        });

        return returnList;
    }

    function displayItem(eleme) {
        var element = eleme;
        var sortList = [];
        var list = [];
        var innerList = "";

        $("div.alt_sld_view", element).each(function (i, li) {
            sortList[i] = $(this).attr("data-emplacement");
        });

        sortList = uniq(sortList);
        list = reSortInnerElem(element, sortList);

        for (var i = 0; i < options["numberItem"]; i++) {
            innerList += list[i];
        }

        element.html(innerList);

        return true;
    }

    return displayItem(element);
};

function goBack(toUrl) {
    if (toUrl) {
        window.location.href = path_relative_root;
    } else {
        var url = window.location.href;

        if (url.indexOf("#search") > -1) {
            var new_url = url.substring(0, url.indexOf("#search"));
            window.location.href = new_url;
        } else {
            window.history.back();
        }
    }
}

// Utilisé pour le scroll
function fix_rayon_display() {
    if ($("#list_item .item").length > 0) {
        var items = $("#list_item .item");
        var itemCount = $("#list_item .item").length;

        if (itemCount % 3 == 2) {
            $("<div class=\"item empty\"></div>").insertAfter(items.last());
        }
    }
}

$('body').on('click', '.search_recap', function () {
    $('#trigger_recherche').click();
    $('#search_home').focus();
});

window.setTimeout(function () {
    if ($(".productSwiper").length) {
        $('body').on('mouseenter touchstart', '.productSwiper', function () {
            if ($(this).find('.swiper-slide-on').hasClass('cache')) {
                $(this).find('.swiper-wrapper').html($(this).find('.swiper-slide-on').html());
                $(this).find('.swiper-slide-off').remove();
                $(this).find('.swiper-slide-on').remove();

                var productSwiper = new Swiper($(this), {
                    slidesPerView: 1,
                    observer: true,
                    observeParents: true,
                    navigation: {
                        nextEl: '.swiper-next',
                        prevEl: '.swiper-prev',
                    },
                    preloadImages: false,
                    lazy: isLazyLoad,
                    loadPrevNext: true,
                    loadOnTransitionStart: true
                });
            }
        });

        // Ajout d'une min-height pour éviter l'effet clignotement
        if (!$('.rayon_lookbook').length) {
            if ($(".swiper-slide-off").find("span").hasClass('ill_img')) {
                $("#list_item").find($(".item")).css("min-height", "276px");
            }
        }
    };
}, 500);

$(function () {
    if ($(".login_form_wrapper .w-newlogin-form").length) {
        var $deja_compte = Translator.translate('existing_user_pwd_reset_' + site_id);

        $(".login_form_wrapper .w-newlogin-form").append($('<p class="deja_compte">' + $deja_compte + '</p>'));
    }
});

function openMultiShad(id) {
    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {
        $("#" + id).slideDown(600);
        if ($("#" + id).hasClass('closed')) {
            $("#" + id).removeClass('closed');
        }
    });

    if (id == 'lightbox_achat_express') {
        $("#" + id).css("transform", "translateX(0)");
    }

    if (id == 'popup_cofidis3cb') {
        // COFIDIS FP
        if ($('#popup_cofidis3cb .popup_container').length) {
            $('#popup_cofidis3cb .popup_container').overlayScrollbars({});
        }
    }

    if (id !== 'zoomBox') {
        $("#shad").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
            closeMultiShad(id);
        });
    }

    if (id === "zoomBox") {
        $('#zoom_box_wrapper .swiper-slide').on('click', function () {
            closeMultiShad('zoomBox');
            $("body").css("overflow", "auto");
        });
    }

    if ($("#buyNowSwiper").length > 0) {
    const slideCount = $("#buyNowSwiper .swiper-slide").length;

        if (slideCount > 1) {
            // Initialize Swiper if more than one slide
            window.setTimeout(function () {
                buyNowSwiper = new Swiper("#buyNowSwiper", {
                    slidesPerView: 1,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
            }, 50);
        } else {
            // Hide arrows if only one slide
            $(".swiper-button.arrow").css('display', 'none');
        }
    }

    if (id === "sizeguideBox") {
        window.setTimeout(function () {
            $("#wrap_itable").overlayScrollbars({});
        }, 300);

        $('body').css('position', 'fixed');
    }

    // Shipup Lightbox
    if (id === "shipup-container") {

        // We check if theres the top-navbar and we add a close button to the lightbox
        if ($('.shipup-top-navbar', "#" + id).length && !$('.custom_shipup_close', "#" + id + " .shipup-top-navbar").length) {
            setTimeout(function () {
                $('.shipup-top-navbar', "#" + id).append('<div class="custom_shipup_close" onclick="closeMultiShad(\'' + id + '\')"><span class="bfr bgSprite cross"></span></div>');
            }, 500);
        }

        setTimeout(function () {
            $("#" + id).find('.shipup-home-container').overlayScrollbars({
                scrollbars: {
                    autoHide: "leave",
                    autoHideDelay: 0,
                }
            });
        }, 500);
    }
}

function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");

    $shad.attr("onclick", "");
    $shad.animate({ opacity: 0 }, 350, function () {
        $shad.css({ display: "none", opacity: "" }).data("opened_lb", 0);
    });
    $modbox.css({ display: "none" });
}

if ($('#completeLookTitle').length === 0) {
    $("#mightLikeTitle").addClass("is-active");
    $(".associations_by_rayon").removeClass("is-hidden");
};

function checkSelectedInput() {
    var inputTaille = $('.product_infos_form.not_achat_express').find('#tailleProd');

    if (inputTaille.length && inputTaille.val() == '') {
        $('#sizeerror').slideDown();
    }
}

/*PROD ASSOCS DATA LAYERS*/
$(function () {
    if (window.$('#gondole_portez')) {
        $('#gondole_portez .swiper-slide').on('click', function () {
            var product_id = event.currentTarget.getAttribute('data-swiper-slide-index') ? event.currentTarget.getAttribute('data-swiper-slide-index') : '';

            if (product_id !== '') {
                var productObj = data_layer_products[product_id];
                productObj.url = $(this).children("a").attr("href");
                var prod = productObj;
                delete prod.ean;
                delete prod.ref;
                delete prod.sku;
                delete prod.refSizeColor;
                delete prod.url;
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': { 'list': productObj['category'] },      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        })
    }
});

$(function () {
    /*
        * Page: Connexion.php
        * Déplacé le cta "En savoir plus" dans le texte
        * "Je souhaite recevoir les meilleures offres par email et SMS"
    */
    var connexionLoginForm = document.querySelector('.w-newlogin-form') || null;

    if (connexionLoginForm !== null) {
        const observer = new MutationObserver(list => {
            const evt = new CustomEvent('dom-changed', { detail: list });
            connexionLoginForm.dispatchEvent(evt);
        });

        observer.observe(connexionLoginForm, { attributes: true, childList: true, subtree: true });

        connexionLoginForm.addEventListener('dom-changed', function (e) {
            if ($(this).find('.optin_container .knowMoreOptin').length && $(this).find('.w-fieldset-label').length) {
                $(this).find('.optin_container .knowMoreOptin').detach().appendTo($(this).find('.w-fieldset-label'));
                
                // Move ":" after "Learn more"
                $(this).find('.colon').insertAfter($(this).find('.knowMoreOptin'));

                observer.disconnect();
            }
        });
    }
});

/*Custom Select Menu*/
function initCustomSelect(select) {
    var x, i, j, selElmnt, a, b, c;

    if (select === undefined) {
        select = 'custom-select';
    }

    x = document.getElementsByClassName(select);

    // Si jamais l'element est déja customisé alors on n'y touche pas
    if ($('.' + select + ' .select-items').length > 0) {
        return;
    }

    for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");

        for (j = 0; j < selElmnt.length; j++) {
            // Si la valur est vide alors on ne la prend pas en compte
            if (selElmnt.options[j].value === '') {
                continue;
            }

            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;

            if (selElmnt.options[j].selected) {
                c.setAttribute("class", "same-as-selected");
            }

            c.addEventListener("click", function (e) {
                var y, i, k, s, h, l;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                h = this.parentNode.previousSibling;
                l = s.length;

                for (i = 0; i < l; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        // Trigger pour lancer l'evènement
                        $(s).trigger('change');
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");

                        for (k = 0; k < y.length; k++) {
                            y[k].removeAttribute("class");
                        }

                        // Pour signaler que c'est cet élément qui doit être sélectionné
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
            });

            b.appendChild(c);
        }

        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
            e.stopPropagation();
            // Raz de tous les autres select
            closeAllSelect(this);

            // On cache/affiche la liste
            this.nextSibling.classList.toggle("select-hide");

            // On change la direction de la fleche
            this.classList.toggle("select-arrow-active");
        });
    }
}
function updateCustomSelect(select_str) {
    if (select_str === undefined) {
        select_str = '.custom-select';
    }

    var select = $(select_str);

    // Evenement sur le select pour bien le gérer
    $('.select-selected', select).on("click", function (e) {
        e.stopPropagation();
        // Raz de tous les autres select
        closeAllSelect(this);

        // On cache/affiche la liste
        this.nextSibling.classList.toggle("select-hide");

        // On change la direction de la fleche
        this.classList.toggle("select-arrow-active");
    });

    // Maintenant pour chacun de ses fils on va mettre un evenement
    $('.select-items div', select).on('click', function () {
        var y, i, k, s, h, l;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        h = this.parentNode.previousSibling;
        l = s.length;

        for (i = 0; i < l; i++) {
            // En parcourant le select on va conparer le innerhtml du select avec le personalisé pour selectionner le bon element
            if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                // Trigger pour lancer l'evènement
                $(s).trigger('change');
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");

                for (k = 0; k < y.length; k++) {
                    y[k].removeAttribute("class");
                }

                // Pour signaler que c'est cet élément qui doit être sélectionné
                this.setAttribute("class", "same-as-selected");
                break;
            }
        }
    });
}

function closeAllSelect(elmnt) {
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");

    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }

    for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}

document.addEventListener("click", closeAllSelect);

$(document).ready(function () {
    initCustomSelect();

    $(".custom-select select").each(function () {
        var count = $(this).find("option").not(':empty').length;

        if (count == 1) {
            $('.custom-select .select-selected').addClass("removeAfter");
        }
    });
});

/*FILTRE RAYON*/
function displayDropdownFilters(from_filtersSubmit) {
    from_filtersSubmit = from_filtersSubmit ? from_filtersSubmit : 0;

    var sticky, filters_dropdown, filter_trigger, shad, bandeau_rayon, header_rayon, sub_categ_rayon, search_recap;
    sticky = $('#filter_sticky');
    filters_dropdown = sticky.find('.filters_dropdown');
    filter_trigger = $('#trigger_filtre')
    shad = $('#shad_link');
    bandeau_rayon = $('.wrapper_bandeau');
    header_rayon = $("#site_head_wrap");
    sub_categ_rayon = $(".page_rayon .bloc_sub_categ")
    search_recap = $(".search_recap");

    if (!filter_trigger.hasClass('triggered')) {
        $('#rayon_sticky_block').addClass('triggered');
        filter_trigger.addClass('triggered');
        filters_dropdown.addClass('show drop');
        filter_trigger.addClass('toClose');
        shad.addClass('actif filter_on absolute');
        bandeau_rayon.addClass('filter_on');
        header_rayon.addClass('filter_on');
        sub_categ_rayon.addClass('filter_on');
        search_recap.addClass('filter_on');
    } else if (filter_trigger.hasClass('triggered')) {
        closeFiltre(from_filtersSubmit);
    }

    if (filter_trigger.hasClass('triggered')) {
        var shad_filtre = $('#shad_link.filterOn');

        shad_filtre.on("click touch", function () {
            closeFiltre(from_filtersSubmit);
        });
    }

    function closeFiltre(from_filtersSubmit) {
        if (filter_trigger.hasClass('triggered')) {
            $('#rayon_sticky_block').removeClass('triggered');
            filter_trigger.removeClass('triggered toClose');
            filters_dropdown.removeClass('show drop');
            shad.removeClass('actif filter_on absolute');
            bandeau_rayon.removeClass('filter_on');
            header_rayon.removeClass('filter_on');
            sub_categ_rayon.removeClass('filter_on');
            search_recap.removeClass('filter_on');

            if (from_filtersSubmit) {
                $("html, body").animate({
                    scrollTop: $('#site_global_wrap').offset().top,
                }, 500);
            }
        }
    }

    if ($('.holder_filters .filter_wrapper').length > 6) {
        $('.holder_filters').addClass('more_filter');
    }
}

if ($('.dynasearchwrapper').length) {
    $('body').addClass('search_result_on');
}

$(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll > 50) {
        $("body").addClass("scroll_on");
    } else {
        $("body").removeClass("scroll_on");
    }
});

$(function () {
    var basketGondole = $('body.cart #main_cart_wrapper + .basket_gondole');
    var leftSideTunnel = $('body.cart #main_cart_wrapper .left_side');

    if (basketGondole.length && leftSideTunnel.length) {
        // Deplacer l'element "basket_gondole" dans "left_side"
        leftSideTunnel.append(basketGondole);
        basketGondole.css("opacity", "1");
    }

    /*
        * Tunnel - Details de livraison
        * Recapitulatif de la commande - scrollbar sur "Total produits"
    */
    if ($('#cart_total .total_produit_dropdown').length) {
        $('#cart_total .total_produit_dropdown').overlayScrollbars({});
    }

});

if ($('#abox .close_pop').length) {
    $('#abox .close_pop').on('click', function () {
        $('#abox, .shad').hide();
    });
}

/*LOOKBOOK*/
if ($('.in_lookbook').length) {
    $("#list_item .item.seo, #list_item .item.lookbook:nth-of-type(2)").wrapAll("<div class='item lookbook'></div>");
}

/*Tunnel - popup produit offert*/
window.setTimeout(function () {
    if ($("body.cart #lightbox_achat_express #buyNowSwiper").length > 0) {
        buyNowSwiper = new Swiper("body.cart #lightbox_achat_express #buyNowSwiper", {
            slidesPerView: 1,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }
}, 100);

$(function () {
    if ($('#selection-offer #selection-offer-content').length) {
        $('#selection-offer #selection-offer-content').overlayScrollbars({});
    }

    $('.cart.step_2_0 #new_address .w-form-line.w-has-tel .w-input-container').append($(".cart.step_2_0 #new_address .phone_countries"));
    
    $('select[name="address"]').on('change', function() {
        setTimeout(() => {
            if ($('.phone_countries').length && !$('#adresseForm .elem_global.delivery .phone_countries').length) {
               $('.phone_countries').clone().appendTo($('#adresseForm .w-form-line.w-has-tel .w-input-container:last')).show();
            }
        }, 500);
    });
    
     $('input[name="typeAdresse"]').on('change', function() {
         setTimeout( () => {
             $('.phone_countries').clone().appendTo($('.cart.step_2_0 #adresseForm .elem_global.bill .w-form-line.w-has-tel'));
        }, 500);
    });
});

/* Scroll to top */
function scrollToTheTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

// Check request validity of the order return
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });
});